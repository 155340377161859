@import url('./font/stylesheet.css');
@tailwind base;
@tailwind components;
@tailwind utilities;


.filter-overlay::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #f6f6f6;
  filter: brightness(0.8); /* Puoi regolare la luminosità secondo le tue preferenze */
  z-index: -1; /* Assicurati che il filtro sia dietro agli elementi interni */
}

.splide__pagination {
  display: block!important;
  left: 50px!important;
  bottom: 10px !important;
}
.splide__pagination__page.is-active {
  background: #212121!important;
}

@media only screen and (max-width: 1280px) {
  .splide__pagination {
    display: block!important;
    text-align: center;
    left: 0!important;
    bottom: 10!important;
  }
}

.inputStyle {
  display: none;
}

.loader {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  position: relative;
  animation: rotate 1s linear infinite
}
.loader::before , .loader::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  inset: 0px;
  border-radius: 50%;
  border: 10px solid #333;
  animation: prixClipFix 2s linear infinite ;
}
.loader::after{
  border-color: #A4CBD0;
  animation: prixClipFix 2s linear infinite , rotate 0.5s linear infinite reverse;
  inset: 6px;
}

@keyframes rotate {
  0%   {transform: rotate(0deg)}
  100%   {transform: rotate(360deg)}
}

@keyframes prixClipFix {
    0%   {clip-path:polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)}
    25%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 0,100% 0,100% 0)}
    50%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)}
    75%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 100%)}
    100% {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 0)}
}